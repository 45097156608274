import { handleSiteModal } from "./util/modalHelperFunctions";

document.addEventListener("DOMContentLoaded", () => {

  const parentContainer = document.querySelector("body");
  parentContainer.addEventListener("click", (event) => {

    if (event.target.matches("[data-modal-open]") || event.target.closest("[data-modal-open]")) {

      // opens modals
      event.preventDefault();
      const openModalID = event.target.closest("[data-modal-open]").getAttribute("data-modal-open");
      const openModal = document.querySelector(`[data-modal="${openModalID}"]`);
      handleSiteModal(openModal, "open");
      
      // deal with specific needs when search is clicked
      if (event.target.matches("#search_button") || event.target.closest("#search_button")) {
        
        // auto focus in search field
        document.getElementById("search-field").focus?.();

        // close menu if open
        document.querySelector(".page-header .menu-wrapper")?.classList.remove("is-visible");
        document.getElementById('hamburger-icon')?.classList.remove('open');
      }

    } else if (event.target.matches("[data-modal-close]") || event.target.closest("[data-modal-close]")) {

      // closes modals
      event.preventDefault();
      const closeModalID = event.target.closest("[data-modal-close]").getAttribute("data-modal-close");
      const closeModal = document.querySelector(`[data-modal="${closeModalID}"]`);
      handleSiteModal(closeModal, "close");


      // if youtube embed inside modal, reset upon close
      const modalVideo = closeModal.querySelector("iframe");
      if (modalVideo) {
        let iframeSrc = modalVideo.src;
        modalVideo.src = iframeSrc;
      }

      // if wait container surrounds submit button, 
      // reset status unless proceed button is clicked
      const btnGroup = document.querySelector(".btn-group");
      if (btnGroup && !event.target.hasAttribute('data-modal-proceed')) {
        btnGroup?.classList.remove("wait");
      }

    }

  });

});
